@mixin nav-component-theme($theme) {
  .horizontal-nav,
  .vertical-nav {
    background-color: #2d3035;
  }

  .tab-bar {
    background-color: #373a3f;
    border-bottom: 1px solid rgba(255, 255, 255, 0.1);
  }
}
