/* You can add global styles to this file, and also import other style files */
@use 'abstracts/variables' as variables;

@use 'base/reset';
@use 'base/typography';
@use 'base/normalize';

@use 'themes/material';

@use 'components/dialog';
@use './components/material/index.scss';
@use './components/patient-search-autocomplete';
@import './abstracts/mat-icon-button';

@include MatIconButtonSize('large', 40px, 24px);
@include MatIconButtonSize('medium', 32px, 19px);
@include MatIconButtonSize('small', 26px, 16px);
@include MatIconButtonSize('tiny', 20px, 12px);
.mat-app-background {
  background-color: variables.$sympliact-primary;
}

.hide-subscript {
  .mat-mdc-form-field-subscript-wrapper {
    display: none;
  }
}

.faded {
  color: variables.$field-title;
}

.italics {
  font-style: italic;
}

.mat-tooltip {
  font-size: 0.8rem !important;
}

.mat-sort-header-container {
  .mat-sort-header-arrow {
    opacity: 1 !important;
    color: variables.$black;
  }
}

.appt-status-menu {
  .mat-menu-content {
    background: variables.$white;
    width: 180px;

    button {
      display: flex;
      align-items: center;
      color: variables.$sympliact-grey-brown;
      opacity: 0.87;
      font-size: 14px;
      height: 30px;

      .appt-status-bubble {
        display: block;
        width: 10px;
        height: 10px;
        background: green;
        border-radius: 50%;
        margin-right: 10px;
      }
    }
  }
}

mat-tab-body .mat-tab-body-content {
  overflow: hidden;
}
