.patient-search-autocomplete {
  mat-option .mdc-list-item__primary-text {
    display: flex;
    align-items: center;
    height: 100%;
    line-height: 1;

    .patient-dob {
      opacity: 0.7;
    }

    .patient-info {
      display: flex;
      flex-direction: column;

      span {
        height: 15px;
        font-size: 0.8rem;
      }
    }

    .no-results-text {
      margin: 0;
      width: 100%;
      text-align: center;
    }
  }
}
