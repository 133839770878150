.referrer-autocomplete {
  .mdc-list-item__primary-text {
    display: flex;
    justify-content: space-between;
    width: 100%;

    img {
      width: 18px;
    }

    .input-option {
      display: flex;
      justify-content: space-between;
      font-size: 0.8rem;
    }

    .referrer-name {
      display: flex;
      flex-direction: column;
      font-size: 0.8rem;
      margin-left: 0.25rem;
      line-height: 1rem;
    }
  }
}
