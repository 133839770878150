.delete-appointment-dialog-container,
.reschedule-appointment-dialog-container {
  mat-dialog-container {
    width: 420px;
    text-align: center;
    font-size: 1rem;

    .mat-dialog-title {
      display: flex;
      flex-direction: column;
      align-items: center;

      img {
        width: 60px;
        height: 60px;
      }
    }

    .mat-dialog-content {
      line-height: 1.38;
      font-size: 1rem;
      opacity: 0.6;

      mat-form-field {
        width: 100%;
      }
    }

    .mat-dialog-actions {
      justify-content: space-around;

      button {
        border-radius: 6px;
        width: 180px;
        height: 40px;
        font-size: 1rem;
      }

      .submit-btn:disabled {
        cursor: not-allowed;
      }
    }
  }
}

.terms-privacy-dialog {
  width: 80vw;
  height: 90vh;
}
