.image-viewer-overlay-panel {
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

cdk-global-overlay-wrapper {
  justify-content: center;
}
