@use '../../abstracts/variables';

.mat-menu-content {
  background-color: variables.$sympliact-offset-black;
}

.care-document-menu {
  .care-document {
    padding: 0.25rem;
    display: flex;

    &__details {
      display: flex;
      flex-direction: column;
      gap: 0.1rem;

      .name {
        font-size: 0.8rem;
      }
    }
  }
}

.mat-mdc-menu-content {
  .active {
    text-decoration: underline;
    text-decoration-thickness: 3px;
    text-decoration-color: variables.$sympliact-tealish;
    text-underline-offset: 3px;
  }
}

.global-file-management-menu {
  .files {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    padding: 0 0.25em;
    max-height: 250px;

    &__file {
      cursor: pointer;
      padding: 0.25rem 0.5rem;
      border: 1px solid variables.$sympliact-tealish;
      border-radius: 5px;

      &:hover {
        background-color: variables.$hover-background;
      }

      &__info {
        display: flex;
        flex-direction: column;
        font-size: 0.8rem;

        &__title {
          color: variables.$white;
        }

        &__subtitle {
          color: variables.$sympliact-light-grey;
          line-height: 1rem;
        }
      }
    }
  }
}
