$nav-bar-height: 70px;
$signup-tab-bar-height: 50px;

$white: #fff;
$red: #c62828;
$light-gray: #eee;
$black: #000;
$yellow: #ffda44;

$sympliact-primary: #212427;
$sympliact-offset-black: #2c3036;
$sympliact-grey-brown: #4a4a4a;
$sympliact-light-grey: #9b9b9b;
$sympliact-darker-grey: #757575;
$sympliact-light-blue: #7eeaf3;
$sympliact-red: #cf6679;
$orange: #f98f4b;
$sympliact-tealish: #00dac4;
$sympliact-success-green: #4caf50;

/* Component based */
$hover-background: rgba(0, 218, 196, 0.2);
$faded-background: #2d3035;
$table-row-border: #a7a7a7;
$panel-background: #2d3035;
$mention-background: #579dff;
$field-title: #c4c4c4;
$yellow-tooltip: #ffda44;
$orange-tooltip: #f98f4b;
$border: rgb(255, 255, 255, 0.1);
$button-success: #3bd16f;
$disabled-grey: dimgray;
$expansion-panel-border: #373a3f;
$mat-table-header: #212427;
$webkit-calendar-picker-indicator: invert(60%) sepia(89%) saturate(1135%) hue-rotate(125deg) brightness(97%)
  contrast(101%);

/* Font Family */
$Roboto-Light: 'Roboto Light';
$Roboto-Light-Italic: 'Roboto Light Italic';
$Roboto: 'Roboto';
$Roboto-Medium: 'Roboto Medium';
$Roboto-Bold: 'Roboto Bold';

/* Min Width Input Sizes */
$input-medium: 250px;
$input-large: 300px;
