@use '../abstracts/variables';

@mixin table-component-theme($theme) {
  table.mat-mdc-table {
    background-color: transparent;

    thead {
      background-color: variables.$mat-table-header;
    }

    th {
      .mat-sort-header-arrow {
        color: variables.$sympliact-tealish;
      }
    }
  }

  mat-paginator.mat-mdc-paginator {
    background-color: transparent;
  }
}
