@use '../../abstracts/variables';

.referrer-dialog {
  .mat-dialog-container {
    position: relative;
  }
}

.mat-mdc-dialog-container {
  --mdc-dialog-container-color: #{variables.$sympliact-primary};
}
